import * as React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Seo from "../components/Seo";
import IndexProject from "../components/IndexProject";

import { useState } from "react";
import { Link } from "gatsby";
import { mobile, mobileUp, tablet, desktop, desktopUp } from "../styles/global";
import { motion, AnimatePresence } from "framer-motion";
import useSiteSettings from "../utils/useSiteSettings";
import useBreakpoint from "../utils/useBreakpoint";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import ProjectsContent from "../components/ProjectsContent";

const ProjectsPage = ({ data }) => {
	const page = data;
	const allProjects = page?.allSanityProject?.projects.filter(
		(p) => p.indexHeroImage.length !== 0
	);

	return (
		<>
			<Seo
				title={"Projects"}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<ProjectsContent allProjects={allProjects} />
		</>
	);
};

export const query = graphql`
	query AllProjectsQuery {
		allSanityProject(sort: { order: ASC, fields: orderRank }) {
			projects: nodes {
				_id
				slug {
					current
				}
				hero {
					heroImage {
						asset {
							_id
							width
							height
							url
							metadata {
								palette {
									_rawDominant
								}
							}
						}
					}
				}
				indexHeroImage {
					asset {
						_id
						width
						height
						url
						metadata {
							palette {
								_rawDominant
							}
						}
					}
				}
				name
				type
				content {
					_key
					slideType
					images {
						... on SanityImageWithCaption {
							_key
							_type
							alt
							caption
							asset {
								gatsbyImageData
								_id
								width
								height
								url
								metadata {
									dimensions {
										aspectRatio
									}
									palette {
										_rawDominant
									}
								}
							}
						}
					}
					images2 {
						... on SanityImageWithCaption {
							_key
							_type
							alt
							caption
							asset {
								gatsbyImageData
								_id
								width
								height
								url
								metadata {
									dimensions {
										aspectRatio
									}
									palette {
										_rawDominant
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default ProjectsPage;

export const Head = () => <title>Home Page</title>;
